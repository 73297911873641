import * as React from 'react';
import fetch from 'isomorphic-fetch';
import Client from 'shopify-buy';

import { isBrowser } from '@utils';

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
);

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  client,
  checkout: {
    lineItems: [],
  },
};

export const StoreContext = React.createContext(defaultValues);

const localStorageKey = `shopify_checkout_id`;

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = React.useState(defaultValues.checkout);
  const [loading, setLoading] = React.useState(false);
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);

  const setCheckoutItem = aCheckout => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, aCheckout.id);
    }

    setCheckout(aCheckout);
  };

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser ? localStorage.getItem(localStorageKey) : null;

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(existingCheckoutID);
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout);
            return;
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null);
        }
      }

      const newCheckout = await client.checkout.create();
      setCheckoutItem(newCheckout);
    };

    initializeCheckout();
  }, []);

  const addVariantToCart = (variantId, quantity) => {
    setLoading(true);

    const checkoutID = checkout.id;

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ];

    return client.checkout.addLineItems(checkoutID, lineItemsToUpdate).then(res => {
      setCheckout(res);
      setLoading(false);
      setDidJustAddToCart(true);
      setIsOpen(true);
      setTimeout(() => setDidJustAddToCart(false), 3000);
    });
  };

  const removeLineItem = (checkoutID, lineItemID) => {
    setLoading(true);

    return client.checkout.removeLineItems(checkoutID, [lineItemID]).then(res => {
      setCheckout(res);
      setLoading(false);
    });
  };

  const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true);

    const lineItemsToUpdate = [{ id: lineItemID, quantity: parseInt(quantity, 10) }];

    return client.checkout.updateLineItems(checkoutID, lineItemsToUpdate).then(res => {
      setCheckout(res);
      setLoading(false);
    });
  };

  const toggleOpen = () => {
    setIsOpen(previous => !previous);
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        checkout,
        loading,
        didJustAddToCart,
        isOpen,
        toggleOpen,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
