const breakpoints = {
  small: 768 / 16,
  medium: 1040 / 16,
  large: 1280 / 16,
  huge: 1680 / 16,
  hd: 1920 / 16,
};

const mq = {
  small: `@media (min-width: ${breakpoints.small}em)`,
  medium: `@media (min-width: ${breakpoints.medium}em)`,
  large: `@media (min-width: ${breakpoints.large}em)`,
  huge: `@media (min-width: ${breakpoints.huge}em)`,
  hd: `@media (min-width: ${breakpoints.hd}em)`,
};

const mqdown = {
  small: `@media (max-width: ${breakpoints.small - 0.01}em)`,
  medium: `@media (max-width: ${breakpoints.medium - 0.01}em)`,
  large: `@media (max-width: ${breakpoints.large - 0.01}em)`,
  huge: `@media (max-width: ${breakpoints.huge - 0.01}em)`,
  hd: `@media (max-width: ${breakpoints.hd - 0.01}em)`,
};

export { mqdown, breakpoints };
export default mq;
