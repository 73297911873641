import React from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';

import mq, { mqdown } from '@mq';
import { linkHover } from '@mixins';
import Cart from '@components/Cart';

const Navigation = () => (
  <Container>
    <NavigationItem to="/projects" activeClassName="navigation-active-projects" partiallyActive>
      Projects
    </NavigationItem>
    <DesktopNavigationItem to="/repository" activeClassName="navigation-active-repository">
      Repository
    </DesktopNavigationItem>
    <NavigationItem to="/store" activeClassName="navigation-active-store" partiallyActive>
      Store
    </NavigationItem>
    <NavigationItem to="/about" activeClassName="navigation-active-about">
      About
    </NavigationItem>
    <CartContainer>
      <Cart />
    </CartContainer>
    <NavigationItem to="/">Studio Nejc Prah</NavigationItem>
  </Container>
);

const Container = styled.nav`
  padding: 0.375rem var(--gutter);

  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;

  font: var(--font-small);
  background-color: var(--color-white);
  color: var(--color-text);

  ${mq.small} {
    padding: 0.375rem;
  }
`;

const NavigationItem = styled(GatsbyLink)`
  ${linkHover};
  margin-left: 1rem;

  ${mq.small} {
    margin-left: 3.125rem;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-left: auto;
  }

  &.navigation-active-projects {
    color: var(--color-blue);
  }

  &.navigation-active-repository {
    color: #931b1b;
  }

  &.navigation-active-about {
    color: var(--color-jade);
  }

  &.navigation-active-store {
    color: var(--color-red);
  }
`;

const DesktopNavigationItem = styled(NavigationItem)`
  color: var(--color-spanish-gray);

  ${mqdown.small} {
    display: none;
  }
`;

const CartContainer = styled.div`
  margin-left: 1rem;

  ${mq.small} {
    margin-left: 3.125rem;
  }
`;

export default Navigation;
