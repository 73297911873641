import React from 'react';
import styled from 'styled-components';

import { StoreContext } from '@context';
import mq, { mqdown } from '@mq';
import { formatPrice } from '@utils';
import { linkHover } from '@mixins';
import Button from '@core/Button';

import LineItem from '@components/LineItem';

const Cart = () => {
  const { isOpen, toggleOpen, checkout, loading } = React.useContext(StoreContext);

  const emptyCart = checkout.lineItems.length === 0;

  const handleCheckout = () => {
    window.open(checkout.webUrl);
  };

  if (emptyCart) {
    return null;
  }

  return (
    <Container>
      <Title onClick={toggleOpen}>cart</Title>
      {isOpen && (
        <CartBox>
          <Header onClick={toggleOpen}>
            <div>shopping cart</div>
            <CloseButton>&#x2715;</CloseButton>
          </Header>
          <LineItems>
            {checkout.lineItems.map(lineItem => (
              <LineItem key={lineItem.id} item={lineItem} />
            ))}
          </LineItems>
          <Total>
            <div>SUBTOTAL</div>
            <div>
              {formatPrice(checkout.subtotalPriceV2.currencyCode, checkout.subtotalPriceV2.amount)}
            </div>
          </Total>
          <Footer>
            <div>Shipping will be calculated at checkout</div>
            <Button type="checkout" onClick={handleCheckout} disabled={loading}>
              checkout
            </Button>
          </Footer>
        </CartBox>
      )}
    </Container>
  );
};

const Container = styled.div`
  --border-color: #00a067;
  --padding-block: 0.75rem;
  --padding-inline: 0.9375rem;

  ${mq.small} {
  }
`;

const Title = styled.div`
  ${linkHover};
  color: #00a067;
  cursor: pointer;
`;

const CartBox = styled.div`
  border: 1px solid var(--border-color);
  border-radius: 1.25rem;
  font: var(--font-small);
  background-color: var(--color-white);
  right: var(--gutter);
  top: calc(1.25 * var(--section-spacing) + var(--navigation-height));
  position: fixed;
  z-index: 1;

  ${mqdown.small} {
    left: var(--gutter);
  }

  ${mq.small} {
    width: 12rem;
    top: calc(1.25 * var(--section-spacing) + var(--navigation-height) - (var(--gutter) / 2));
    right: calc(var(--gutter) / 2);
  }
`;

const Header = styled.div`
  text-transform: uppercase;
  border-bottom: 1px solid var(--border-color);
  padding: var(--padding-block) var(--padding-inline);
  cursor: pointer;
`;

const CloseButton = styled.div`
  ${linkHover};
  position: absolute;
  right: var(--padding-inline);
  top: var(--padding-block);
`;

const LineItems = styled.ul`
  border-bottom: 1px solid var(--border-color);
  padding: var(--padding-block) var(--padding-inline);
  display: grid;
  row-gap: 1em;
  color: var(--color-black-light);
`;

const Total = styled.div`
  border-bottom: 1px solid var(--border-color);
  padding: var(--padding-block) var(--padding-inline);
`;

const Footer = styled.div`
  padding: var(--padding-block) var(--padding-inline);
  display: grid;
  row-gap: 1em;
  color: var(--color-black-light);
`;

export default Cart;
