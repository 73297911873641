import React from 'react';
import styled from 'styled-components';
import { linkHover } from '@mixins';

const NumericInput = ({ onIncrement, onDecrement, disabled, quantity }) => (
  <Container>
    <Label>quantity</Label>
    <Button disabled={disabled} onClick={onDecrement}>{`<`}</Button>
    <div>{quantity}</div>
    <Button disabled={disabled} onClick={onIncrement}>{`>`}</Button>
  </Container>
);

const Container = styled.div`
  display: flex;
`;

const Label = styled.div`
  margin-right: var(--space-quarter);
`;

const Button = styled.button`
  ${props => !props.disabled && linkHover};
  padding-left: var(--space-quarter);
  padding-right: var(--space-quarter);
  display: flex;
  justify-content: center;
`;

export default NumericInput;
