import * as React from 'react';
import styled, { css } from 'styled-components';

import withLink from '@common/withLink';

import { hover } from '@mixins';

const Button = ({ type, title, children, ...rest }) => (
  <Container type={type} title={title} {...rest}>
    {children}
  </Container>
);

const Container = styled.button`
  ${setColors};

  padding: 0.25rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font: var(--font-large);

  border-radius: 4.6875rem;
  cursor: pointer;

  border: 1px solid var(--content-color);
  background-color: var(--background-color);
  color: var(--content-color);

  ${hover`
    --background-color: var(--hover-color);
  `}

  &:active {
    --background-color: var(--active-color);
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.3;
  }
`;

function setColors({ type }) {
  switch (type) {
    case 'checkout':
      return css`
        --content-color: #00a067;
        --hover-color: #fbff39;
        --active-color: #e9ed00;
      `;
    case 'addtocart':
      return css`
        --content-color: #fff;
        --hover-color: #ff8f8f;
        --active-color: #ff5b5b;
      `;
    case 'cart':
    default:
      return css`
        --content-color: #00a067;
        --hover-color: #fad1ab;
        --active-color: #ffbe82;
      `;
  }
}

export default withLink(Button);
