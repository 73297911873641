import { createGlobalStyle } from 'styled-components';
import mq from '@mq';

const Vars = createGlobalStyle`
  :root {
    --space-quarter: 0.3125rem; // 5
    --space-half: 0.625rem; // 10
    --space: 1.25rem; // 20
    --space-plus-half: 1.875rem; // 30
    --space-double: 2.5rem; // 40
    --space-triple: 3.75rem; // 60
    
    --layout-max: 100%;
    --gutter: 0.75rem; // 12
    --grid-gap: calc(var(--section-spacing-base) * 2); // 60

    ${mq.small} {
      --gutter: 2.5rem; // 40
      --grid-gap: 2.5rem; // 40
    }

    //navigation
    --navigation-height: 1.9375rem;

    ${mq.small} {
      --navigation-height: 1.75rem;
    }

    //sections
    --section-spacing-base: 1.875rem; // 60 / 2 = 30
    --section-spacing: var(--section-spacing-base);

    ${mq.small} {
      --section-spacing-base: 3.75rem; // 120 / 2 = 
    }

    --font-family: 'Unica77', sans-serif;
    
    --font-default: 0.9375rem / 1.267 var(--font-family); // 15/19
    --font-small: var(--font-default); // 15/19
    --font-large: var(--font-default); // 15/19
    --font-huge: var(--font-default); // 15/19

    ${mq.small} {
      --font-default: 1.0625rem / 1.235 var(--font-family); // 17/21
      --font-small: 0.8125rem / 1.231 var(--font-family); // 13/16
      --font-large: 1.5625rem / 1.2 var(--font-family); // 25/30
      --font-huge: 2.8125rem / 1.1778 var(--font-family); // 45/53
    }

    --color-white: #fff;
    --color-black: #000;
    --color-black-light: rgba(0,0,0,0.5);
    --color-black-lighter: rgba(0,0,0,0.25);
    --color-grey: #cccccc;
    --color-spanish-gray: #949494;

    --color-text: var(--color-black);
    --color-text-light: var(--color-black-light);
    --color-link: var(--color-blue-link);
    
    --color-blue: rgba(66,0,255); /* #4200FF */
    --color-blue-light: rgba(66,0,255,0.5);
    --color-blue-link: #0057FF;
    --color-blue-store-info: #5B9CFF;

    --color-red: rgba(255,61,0); /* #FF3D00 */
    --color-red-light: rgba(255,61,0,0.5);

    /* store */
    --color-price: #0047FF;

    /* about page */
    --color-jade: #00C968; /* navigation */
    --color-pesto: #697233; /* map */
    --color-sangria: #9A0000; /* instagram */

    --color-green: #00C968; /* exhibitions */
    --color-indigo: #361867; /* workshops */
    --color-ocean: #194578; /* press */
    --color-brick: #8F3C00; /* awards */

    --motion: 200ms;
  }
`;

export default Vars;
