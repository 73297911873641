import { createGlobalStyle, css } from 'styled-components';
import mq from '@mq';

import { truncate, layoutWrapper } from '@mixins';

const Base = createGlobalStyle`
  html,
  body {
    font-size: 16px;
    font-family: var(--font-family);
    line-height: 1.25;
    height: 100%;
    width: 100%;
    background: var(--color-white);
    color: var(--color-text);
    
    -webkit-font-smoothing: antialiased;
  }

  main {
    padding-top:  calc(1.25 * var(--section-spacing));
  }

  section {
    --section-spacing: calc(var(--section-spacing-base) * var(--section-spacing-multiplier, 1));
    
    ${layoutWrapper};

    padding-top: var(--section-spacing);
    padding-bottom: var(--section-spacing);

    &:first-child {
      padding-top: 0;
    }
  }

  h1 {
    font: var(--font-large);
  }

  h2 {
    font: var(--font-large);
  }

  h3 {

  }

  h4 {

  }

  small {

  }

  img {
    max-width: 100%;
    display: block;
  }
`;

export default Base;
