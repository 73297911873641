import { css, keyframes } from 'styled-components';

const grid = css`
  display: grid;
  column-gap: var(--grid-gap);
`;

const layoutWrapper = css`
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  max-width: var(--layout-max);
`;

const maxPageWidth = css`
  --layout-max: 90rem;
  max-width: var(--layout-max);
  margin: 0 auto;
`;

const truncate = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const heroBlockNudge = css`
  /* if the section is a hero block, nudge it up a bit */
  &:first-child {
    margin-top: calc(-1.25 * var(--section-spacing));
  }
`;

const customSpacing = css`
  --section-spacing-multiplier: ${props => props.spacing};
`;

const hover = content => css`
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${css(content)};
    }
  }
`;

const linkHover = css`
  transition: color var(--motion);

  ${hover`
    color: var(--color-blue-link);
  `}
`;

const richText = css`
  p {
    white-space: pre-line;
  }

  p + p {
    margin-top: 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1em;
  }

  p + h1 {
    margin-top: 1em;
  }

  a {
    color: var(--color-link);

    ${linkHover};
  }

  ul {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;

    li {
      display: flex;

      &:before {
        content: '•';
        display: block;
        margin: 0 1rem;
        height: 100%;
      }
    }
  }
`;

const cover = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
`;

const appearKeyframes = keyframes`
  from {
    opacity:  0;
  }

  to {
    opacity:  1;
  }
`;

const appear = css`
  animation: ${appearKeyframes} 1s var(--motion-delay, 0s) backwards;
`;

export {
  customSpacing,
  richText,
  grid,
  truncate,
  layoutWrapper,
  hover,
  linkHover,
  cover,
  appear,
  maxPageWidth,
  heroBlockNudge,
};
