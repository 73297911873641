import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import { hover } from '@mixins';
import mq from '@mq';
import withLink from '@common/withLink';

const CookieBanner = () => {
  const [showCookieBanner, setShowCookieBanner] = useState(true);

  const confirmConsent = useCallback(() => {
    window.localStorage.setItem('cookieSet', 'true');
    setShowCookieBanner(false);
  }, []);

  if (!showCookieBanner || window.localStorage.getItem('cookieSet') === 'true') {
    return null;
  }

  return (
    <Container>
      <div>This website uses cookies</div>
      <Button onClick={confirmConsent}>OK</Button>
      <MoreInfo to="/store-information">?</MoreInfo>
    </Container>
  );
};

const Container = styled.div`
  --background-color: var(--color-background);

  position: fixed;
  bottom: var(--space);
  left: var(--space);
  right: var(--space);
  border-radius: 2.5rem;
  background-color: var(--color-white);
  padding: 0.9375rem;
  color: var(--color-brick);
  z-index: 3;
  font: var(--font-small);
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;

  ${mq.small} {
    padding: 0.3125rem 0.5rem 0.3125rem 1rem;
    right: initial;
    left: 50%;
    transform: translateX(-50%);
    column-gap: 0.375rem;
  }
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-blue);
  color: var(--color-blue);
  border-radius: 50%;
  text-transform: uppercase;
  height: 3.375rem;
  width: 3.375rem;
  cursor: pointer;
  transition:
    background-color var(--motion),
    color var(--motion);

  ${mq.small} {
    height: 1.875rem;
    width: 1.875rem;
  }

  ${hover`
    background-color: #FBFF39;
  `}

  &:active {
    background-color: #24e19d;
  }
`;

const MoreInfo = withLink(styled(Button)`
  color: var(--color-red);
  border-color: var(--color-red);

  ${hover`
    background-color: #82E1FF;
  `}

  &:active {
    background-color: #3300ff;
  }
`);

export default CookieBanner;
