/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import styled from 'styled-components';
import debounce from 'lodash.debounce';

import { StoreContext } from '@context';
import { formatPrice } from '@utils';
import NumericInput from '@components/NumericInput';
import { linkHover } from '@mixins';

const LineItem = ({ item }) => {
  const { removeLineItem, checkout, updateLineItem, loading } = React.useContext(StoreContext);
  const [quantity, setQuantity] = React.useState(item.quantity);

  const price = formatPrice(item.variant.priceV2.currencyCode, Number(item.variant.priceV2.amount));

  const subtotal = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount) * quantity
  );

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id);
  };

  const uli = debounce(value => updateLineItem(checkout.id, item.id, value), 300);
  // eslint-disable-next-line
  const debouncedUli = React.useCallback((value) => uli(value), [])

  const handleQuantityChange = value => {
    if (value !== '' && Number(value) < 1) {
      return;
    }
    setQuantity(value);
    if (Number(value) >= 1) {
      debouncedUli(value);
    }
  };

  function doIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1);
  }

  function doDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1);
  }

  return (
    <li>
      <div>{item.title}</div>
      <NumericInput
        disabled={loading}
        quantity={quantity}
        onIncrement={doIncrement}
        onDecrement={doDecrement}
      />
      <div>{subtotal}</div>
      <Remove onClick={handleRemove} disabled={loading}>
        remove
      </Remove>
    </li>
  );
};

const Remove = styled.button`
  ${props => !props.disabled && linkHover};
  cursor: pointer;
  color: var(--color-black-lighter);
  display: flex;
  justify-content: center;
`;

export default LineItem;
