import * as React from 'react';
import { Link } from 'gatsby';
import { isExternalLink } from '@utils';

const withLink = Component => {
  const ComponentWithLink = props => {
    const { to } = props;
    const { title } = props;

    if (!to) {
      return <Component {...props} />;
    }

    if (isExternalLink(to)) {
      return (
        <Component
          as="a"
          href={to}
          title={title}
          target="_blank"
          rel="noopener noreferrer"
          {...props}
        />
      );
    }

    return <Component as={Link} to={to} title={title} {...props} />;
  };

  return ComponentWithLink;
};

export default withLink;
