exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-repository-js": () => import("./../../../src/pages/repository.js" /* webpackChunkName: "component---src-pages-repository-js" */),
  "component---src-pages-store-information-js": () => import("./../../../src/pages/store-information.js" /* webpackChunkName: "component---src-pages-store-information-js" */),
  "component---src-pages-store-js": () => import("./../../../src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-product-type-js": () => import("./../../../src/templates/ProductType.js" /* webpackChunkName: "component---src-templates-product-type-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/Project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-project-type-js": () => import("./../../../src/templates/ProjectType.js" /* webpackChunkName: "component---src-templates-project-type-js" */)
}

