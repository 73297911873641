const defaultGutter = `0.75rem`;
const defaultGutterMedium = `2.5rem`;

// const gridGap = `1.875rem`;
const gridGapMedium = `2.5rem`;

function getImageSizes(siblingCount, width) {
  const containerWidth = getContainerWidth(width);
  const gutter = width === 'full' ? '0rem' : defaultGutter;
  const gutterMedium = width === 'full' ? '0rem' : defaultGutterMedium;

  if (!siblingCount) {
    return null;
  }
  return `(min-width: 48em) calc((${containerWidth}vw - 2 * ${gutterMedium} - ${
    siblingCount - 1
  } * ${gridGapMedium}) / ${siblingCount}), calc(100vw - 2 * ${gutter})`;
}

function getContainerWidth(width) {
  switch (width) {
    case 'xxx-small':
      return 20;
    case 'xx-small':
      return 30;
    case 'x-small':
      return 45;
    case 'small':
      return 60;
    case 'medium':
      return 75;
    case 'large':
    default:
      return 100;
  }
}

export default getImageSizes;
