import React from 'react';

import GlobalStyles from '@styles/GlobalStyles';
import Navigation from '@core/Navigation';
import CookieBanner from '@core/CookieBanner';
import { isBrowser } from '@utils';

const Layout = ({ children }) => (
  <>
    <GlobalStyles />
    <Navigation />
    {children}
    {isBrowser && <CookieBanner />}
  </>
);

export default Layout;
