import { css } from 'styled-components';
import mq from '@mq';

function getContentBlockWidth({ width }) {
  switch (width) {
    case 'xxx-small':
      return css`
        ${mq.small} {
          width: 20%;
          margin: 0 auto;
        }
      `;
    case 'xx-small':
      return css`
        ${mq.small} {
          width: 30%;
          margin: 0 auto;
        }
      `;
    case 'x-small':
      return css`
        ${mq.small} {
          width: 45%;
          margin: 0 auto;
        }
      `;
    case 'small':
      return css`
        ${mq.small} {
          width: 60%;
          margin: 0 auto;
        }
      `;
    case 'medium':
      return css`
        ${mq.small} {
          width: 75%;
          margin: 0 auto;
        }
      `;
    case 'full':
      return css`
        --gutter: 0;
      `;

    default:
      return null;
  }
}

export default getContentBlockWidth;
